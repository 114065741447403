<template>
  <div>
    <hero />

    <contents />
  </div>
</template>

<script>
  export default {
    components: {
      Hero: () => import('@/components/sample/Hero'),
      Contents: () => import('@/components/sample/Contents')
    }
  }
</script>
